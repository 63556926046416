import React from "react"
import Helmet from "react-helmet"
import Row from "react-bootstrap/Row"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Navbar from "../components/navbar"
import Layout from "../components/layout"
import "./software.scss"
import SEO from "../components/seo"
import { Link } from "gatsby"
import ListGroup from 'react-bootstrap/ListGroup'

const PrepareGroupThreePage = () => (
    <Layout>
        <Helmet>
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no"
            />
        </Helmet>
        <SEO title="3 группа - билеты для подготовки к экзамену" />

        <Navbar />
        <section className="quizzes-section-telis">
            <Container fluid>
                <Row>
                    <Col
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="align-self-center my-5 pt-5"
                    >
                        <h1 style={{
                            color: "var(--textNormal)",
                            wordBreak: "break-all",
                            transition: "color 0.2s ease-out, background 0.2s ease-out",
                        }} className="font-weight-bold text-center mb-0">
                            Билеты для подготовки для 3 группы
        </h1>
                        <h2 className="pt-5 w-90 text-center mx-auto" style={{
                            color: "var(--textNormal)",
                            transition: "color 0.2s ease-out, background 0.2s ease-out",
                            maxWidth: "1140px",
                        }}>В данном разделе собраны билеты для подготовки к экзамену для 3 группы. Вы можете выбрать необходимое количество тестов для прохождения, прежде чем перейти к экзамену.</h2>
                        <Container>
                            
                            <h2 className="text-center mt-5">Билеты</h2>
                            <div className="d-grid text-center my-2">
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/avSPw2p2Nd4nX35k6" target="_blank"><div className="border border-info py-4 my-3">Билет 1</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/DBobB5r85pekwjkP9" target="_blank"><div className="border border-info py-4 my-3">Билет 2</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/517d3asCZHe7dyis8" target="_blank"><div className="border border-info py-4 my-3">Билет 3</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/GtyAtaokrGDwS4uW8" target="_blank"><div className="border border-info py-4 my-3">Билет 4</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/esFptbD8FsQPXUUWA" target="_blank"><div className="border border-info py-4 my-3">Билет 5</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/6AdsAo4xTYi16uAEA" target="_blank"><div className="border border-info py-4 my-3">Билет 6</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/u3N2Rf8fPQCQMKWEA" target="_blank"><div className="border border-info py-4 my-3">Билет 7</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/8hNU4SopfTKHSx4j7" target="_blank"><div className="border border-info py-4 my-3">Билет 8</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/iRu94caiPuNJ4edJ7" target="_blank"><div className="border border-info py-4 my-3">Билет 9</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/k5VxawKnvM2FKJeD9" target="_blank"><div className="border border-info py-4 my-3">Билет 10</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/H6xYppxK8eqLXZWRA" target="_blank"><div className="border border-info py-4 my-3">Билет 11</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/PbM4CnrQ1MCgBvCw7" target="_blank"><div className="border border-info py-4 my-3">Билет 12</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/xbraGCp2mXygZvky9" target="_blank"><div className="border border-info py-4 my-3">Билет 13</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/noKjZTLY3BvM15pY7" target="_blank"><div className="border border-info py-4 my-3">Билет 14</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/z1yF7hkf6eKMaLbx5" target="_blank"><div className="border border-info py-4 my-3">Билет 15</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/qyGCbzotVdJScd1u9" target="_blank"><div className="border border-info py-4 my-3">Билет 16</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/f7W69WZkmBDA3nZJ7" target="_blank"><div className="border border-info py-4 my-3">Билет 17</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/ikDQPWWM8fEQCT72A" target="_blank"><div className="border border-info py-4 my-3">Билет 18</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/K28HyAYPN5WyR8Tj8" target="_blank"><div className="border border-info py-4 my-3">Билет 19</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/SLAkaH3qVYQ7mK686" target="_blank"><div className="border border-info py-4 my-3">Билет 20</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/MCSNg38gCf2EdNSq9" target="_blank"><div className="border border-info py-4 my-3">Билет 21</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/BNtXccv4CGz28TiXA" target="_blank"><div className="border border-info py-4 my-3">Билет 22</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/ie3qREwsGagADcQ78" target="_blank"><div className="border border-info py-4 my-3">Билет 23</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/3HkZBvqyiiNQ4FQU8" target="_blank"><div className="border border-info py-4 my-3">Билет 24</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/W3VqVYYc3snNee3F7" target="_blank"><div className="border border-info py-4 my-3">Билет 25</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/bfM8JfiPwqnHGjbV7" target="_blank"><div className="border border-info py-4 my-3">Билет 26</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/zLUi8YXRjvc1uwZY7" target="_blank"><div className="border border-info py-4 my-3">Билет 27</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/CN65qct7yBKxxNjk7" target="_blank"><div className="border border-info py-4 my-3">Билет 28</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/W4wdMZgcW5idgnAv6" target="_blank"><div className="border border-info py-4 my-3">Билет 29</div></a>
                            </div>
                            
                        </Container>

                    </Col>

                </Row>
            </Container>
        </section>
    </Layout>
)

export default PrepareGroupThreePage
